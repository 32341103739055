import ecommerceRouter from './ecommerce';
import userRouter from './user';
import dashboardRouter from './dashboard/index';
import filesRouter from './files';
import fundsRouter from './funds';
import reportsRouter from './reports';
import comissionReportRouter from './comission-report';
import transactionMonitoringRouter from './transaction-monitoring';
import chargebackRouter from './chargeback';
import onlineRegistrationRouter from './online-registration';
import customerStatementRouter from './customer-statement';
import negatives from './negatives';
import scrRouter from './scr';
import liquidationRouter from './liquidation';
import conciliationRouter from './conciliation';
import accessRouter from './access';
import creditPlatformRouter from './credit-platform';
import balanceRouter from './balance';
import transactionsReportRouter from './transaction-report';
import accountScrowRoute from './account-scrow';
import ntkRoute from './ntk';
import corpActionPlan from './corp-action-plan';
import messageRouter from './message';
import taxRouter from './tax';
import assignorRouter from './assignor';
import clientRentabilityRouter from './clients-rentability';
import fgtsRouter from './fgts';
import taskRouter from './task';
import installmentsRouter from './installments';
import monitoringProfileRouter from './monitoring-profile';
import archivesConciliation from './archives-conciliation';

export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
    },
    ...fgtsRouter,
    ...assignorRouter,
    ...clientRentabilityRouter,
    ...messageRouter,
    ...negatives,
    ...ecommerceRouter,
    ...userRouter,
    ...filesRouter,
    ...fundsRouter,
    ...reportsRouter,
    ...comissionReportRouter,
    ...transactionMonitoringRouter,
    ...chargebackRouter,
    ...onlineRegistrationRouter,
    ...customerStatementRouter,
    ...scrRouter,
    ...liquidationRouter,
    ...conciliationRouter,
    ...accessRouter,
    ...creditPlatformRouter,
    ...balanceRouter,
    ...transactionsReportRouter,
    ...accountScrowRoute,
    ...ntkRoute,
    ...taxRouter,
    ...corpActionPlan,
    ...dashboardRouter,
    ...taskRouter,
    ...installmentsRouter,
    ...monitoringProfileRouter,
    ...archivesConciliation,
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
];
