import permissions from '@/enums/permissions';

const archivesConciliation = [
    {
        path: '/conciliacao-arquivos',
        name: 'archives-conciliation',
        component: () => import('@/views/pages/ArchivesConciliation/ArchivesConciliation.vue'),
        meta: {
            permissions: permissions,
        },
    },
];

export default archivesConciliation;
